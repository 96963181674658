.App {
  text-align: center;
}
.page-head {
    background-color:#ffffffaa;
}
.centered-nav {
    place-self: center;
}

.omilia 
{
    padding:30px 10px;
}

.om-title {
    font-size: 26px;
    font-weight: 600;
    text-align: left;
    padding-bottom:20px;
}

.om-text 
{
    font-size:16px;
    text-align:left;
    padding-bottom:20px;
}

.om-sign 
{
    text-align:left;
}


.boxed-bg {
    padding-top: 40px;
    padding-bottom: 20px;
}
.title-container 
{
    padding-bottom:20px;
    text-align:left;
    padding-left:15px;
}
.box-container .box-item {
    padding:20px;
}
.box-container .box-item-holder {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    box-shadow: 0px 0px 8px 7px #8888887d;
    padding: 15px;
}
.bg-center {
    background-position:center;
} .bg-cover 
{
      background-size:cover;
}
.box-container .box-row-title {
    font-size: 25px;
    font-weight: 600;
    color: #006D7E;
    margin-bottom: 10px;
}
.box-container .box-row-text {
    font-size:16px;
    margin-top:auto;
}
.box-container .box-row-text p{
    margin:0px;
}
.navbar-custom a {
    margin-left: 10px;
    margin-right: 10px;
}
.navbar-custom * {
    text-decoration:none;
}
    .navbar-custom p {
    padding: 5px 10px;
    text-decoration: none;
    color: black;
    font-weight:bold;
}
.navbar-custom {
    place-self: center;
    display: flex;
    justify-content: flex-end;
}

.rec-carousel button {
    position: absolute;
}
    .rec-carousel button.rec-arrow-left {
        left: 10px;
        z-index: 100;
    }
    .rec-carousel button.rec-arrow-right {
        right: 10px;
        z-index: 100;
    }
.rec-carousel img{
    width:100%;
    height:auto;
}
.rec-slider-container {
    margin: 0px !important;
}
.rec-carousel {
    position: relative;
}

.slider-homepage .carousel-track {
    height: 100% !important;
}

.custom-nav {
    justify-content: end !important;
}
.text-align-left 
{
    text-align:left;
}
.rec-pagination 
{
    display:none!important;
}
.text-color-white 
{
    color:white;
}


.btn-holder a
{
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.btn-holder {
    display: flex;
    justify-content: center;
}
.btn-book {
    background-color: #38C1CF;
    border: 1px solid #38C1CF;
    width: 200px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s background-color;
}
.btn-holder a:hover {
    color: white;
}
    .btn-holder a:hover .btn-book {
        background-color: #38C1CF00;
    }
        .blue-box {
    background-color: #006D7E;
}
footer {
    width: 100%;
    background-color: #333;
    color: #fff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
